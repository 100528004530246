const links = [
    {
        id: 1,
        tolink: '/',
        namelink: 'Home 01'
    },
    {
        id: 2,
        tolink: '/home-02',
        namelink: 'Home 02'
    },
    {
        id: 3,
        tolink: '/home-03',
        namelink: 'Home 03'
    },
    {
        id: 4,
        tolink: '/home-04',
        namelink: 'Home 04'
    }
];


export default links ;