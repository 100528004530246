import React, { Component } from 'react';
//import {Link} from 'react-router-dom'
class Footer extends Component {
    render() {
        return (          
            <div className="section slide-personal-Intro-sixth slide-dark" id="contact">                
                <footer className="footer footer-s1 footer-s1-home1">
                    <div id="footer-widget" className="footer-widget-s1 footer-widget-line bg-s1 position-relative">
                    <div className="container">
                        <div className="row d-lg-flex align-items-center text-center">
                        <div className="col-lg-12">
                            <h3 className="widget-title larger lt-sp06">Rester connecté</h3>
                        </div>
                        <div className="col-lg-12">
                            <div className="widget-info">
                            <p className="address">Montréal, Canada</p>
                            <p><a href="mailto:info@perfecto-agency.ca" className="mail" title='info@perfecto-agency.ca'>info@perfecto-agency.ca</a></p>
                            <p><a href="tel:+15145690454" className="phone" title='+1 514 569 04 54'>+1 514 569 04 54</a></p>
                        </div>
                        <div className="col-lg-12">
                            <div className="site-list site-list-pdl text-center">
                            <a href="https://www.linkedin.com/company/agence-perfecto" className="bg-s1" aria-label="Perfecto LinkedIn"><i className="fa fa-linkedin" aria-hidden="true" /> </a>
                            <a href="https://www.behance.net/agenceperfecto" className="bg-s2" aria-label="Perfecto Behance"><i className="fa fa-behance" aria-hidden="true" /> </a>
                            <a href="https://dribbble.com/perfectoagency" className="bg-s3" aria-label="Perfecto Dribbble"><span className="fa fa-dribbble" /> </a>
                            </div>
                            <div itemscope="" itemtype="http://schema.org/Product">
                                <div itemprop="name">Agence Perfecto</div>
                                    <div className="hidden-ap">
                                        <span itemprop="description">Perfecto, votre partenaire en transformation numérique. Des solutions web sur mesure pour le succès en ligne des PME.</span>
                                        <span itemprop="brand">Agence Perfecto</span>
                                        <span itemprop="productID">Agence-Perfecto</span>
                                        <img itemprop="image" src="https://perfecto-agency.ca/images/logo/perfecto-logo01@2x.png" alt="Agence Perfecto"/>
                                        <span itemprop="sku">Agence-Perfecto</span>
                                    </div>
                                    <div itemprop="aggregateRating" itemscope="" itemtype="http://schema.org/AggregateRating">
                                        <span itemprop="ratingValue"><strong>5</strong></span> 
                                        <span className='stars-ap'>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </span>
                                        <span itemprop="ratingCount">7</span> 
                                        <a href='https://tinyurl.com/3ztdueyw' target='_blank' rel="noopener noreferrer"> avis</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div id="bottom" className="bottom-s1">
                    <div className="container d-lg-flex justify-content-between">
                        <div className="copyright lt-sp02">
                        © <a href="https://perfecto-agency.ca/" title='Perfecto Web Agency'>Agence Perfecto</a>, 2023 Tous les droits sont réservés.
                        </div>
                        <div className="socials-list color-s1">
                        <a href="https://www.linkedin.com/company/agence-perfecto" aria-label="Perfecto LinkedIn"><i className="fa fa-linkedin" aria-hidden="true" /> </a>
                        <a href="https://www.behance.net/agenceperfecto" aria-label="Perfecto Behance"><i className="fa fa-behance" aria-hidden="true" /> </a>
                        <a href="https://dribbble.com/perfectoagency" aria-label="Perfecto Dribbble"><i className="fa fa-dribbble" aria-hidden="true" /> </a>
                        </div>
                    </div>
                    </div>
                </footer>             
            </div>
        );
    }
}

export default Footer;
