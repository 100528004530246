import React, { Component } from 'react';
// import {Link} from 'react-router-dom'

class ContainerLeft extends Component {
    render() {
        return (
            <div className="col-left animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
                <div className="flat-title t1">
                    <h4 className="sub-title mb-2">Portfolio</h4>
                    <h2 className="title-section color-d12 mg-b34">Réalisations et études de cas</h2>
                    <div className="description">
                        <p className="lt-sp01">
                        Notre expertise couvre les domaines de la Création de sites web professionnels, de sites e-commerce, l'élaboration de stratégie de marketing digital, 
                        le développement d'applications sur mesure, l'édition et l'intégration de solutions digitales.  
                        <br/><br/>
                        L'Agence Perfecto propose aux PME un service de conception web personnalisé pour répondre à vos besoins spécifiques.
                        </p>
                    </div>
                    {/* <div className="fl-btn btn-general btn-hv-border">
                        <Link to="#" className="f-w500 text-one color-1 lt-sp1 border-corner2">View Case Study</Link>
                    </div> */}
                </div>
          </div>
        );
    }
}

export default ContainerLeft;
