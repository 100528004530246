import React, { Component } from 'react';

class ContainerRight extends Component {
    constructor(props){
        super(props);
        this.state = {
            slider: [
                {
                    id: 1,
                    image: './images/section/smooth-soft-vr.png',
                    alt: 'Smooth VR',
                    caption: 'smoothsoft.ca',
                    siteurl: 'https://smoothsoft.ca/'
                },
                {
                    id: 2,
                    image: './images/section/cashcache.png',
                    alt: 'Money Matters',
                    caption: 'cashcache.ca',
                    siteurl: 'https://cashcache.ca/'
                },
                {
                    id: 3,
                    image: './images/section/chronoexpress.png',
                    alt: 'Chrono Express',
                    caption: 'chronoexpress.ca',
                    siteurl: 'https://chronoexpress.ca/'
                },
                {
                    id: 4,
                    image: './images/section/quickdealer.png',
                    alt: 'Quick Dealer',
                    caption: 'quickdealer.com',
                    siteurl: 'https://quickdealer.com/'
                },
                {
                    id: 5,
                    image: './images/section/planhub.png',
                    alt: 'Planhub',
                    caption: 'planhub.ca'
                },
                {
                    id: 6,
                    image: './images/section/esim.png',
                    alt: 'Simbud',
                    caption: 'simbud.com'
                },
                {
                    id: 7,
                    image: './images/section/genie-ai.png',
                    alt: 'Genie AI',
                    caption: ''
                },
                {
                    id: 8,
                    image: './images/section/Charte-Graphique-Yesso_Page_05.png',
                    alt: 'Yesso',
                    caption: ''
                },
                {
                    id: 9,
                    image: './images/section/bct.jpg',
                    alt: 'BCT',
                    caption: ''
                }
            ]
        }
    }
    render() {
        return (
            <div className="col-right animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                <div className="flat-spacer" data-desktop="4" data-mobile="70" data-smobile="70"></div>
                <div className="slider-drop-apps custom-dot dot-t2 dot-s2 number-pages none-nav-default mg-dots-s2">
                    <div className="flat-carousel-box data-effect clearfix" data-gap="0" data-column="1" data-column2="1" data-column3="1" data-column4="1" data-column5="1" data-dots="true" data-auto="false" data-nav="false" data-loop="true">
                        <div className="owl-carousel">
                            {
                                this.state.slider.map(data => (
                                    <div className="featured-post" key={data.id}>
                                        <img src={data.image} alt={data.alt} />
                                        <div className="caption">
                                        {data.siteurl ? (
                                            <a href={data.siteurl} title={data.alt} target='blank' rel="nofollow">
                                            {data.caption}
                                            </a>
                                        ) : (
                                            <span>{data.caption}</span>
                                        )}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContainerRight;
