import React, { Component } from 'react';

class Testimonial extends Component {
    constructor(props){
        super(props);
        this.state = {
            testimonial: [
                {
                    id: 1,
                    img: './images/testimonial/smoothsoft.jpg',
                    alt: 'images',
                    text01: 'Mon expérience avec Imane et l\'Agence Perfecto était tout simplement parfaite. ',
                    text02: 'L\'Agence est réellement experte dans le domaine de la programmation web, ',
                    text03: 'de base de données et en matière de SEO. Le travail a été effectué avec efficacité et facilité. À voir les designs créés initialement, nous avons donné carte blanche en terme de design. Je recommande l\'Agence Perfecto à tous!',
                    name: 'Smooth Soft Vr',
                    classname: 'testimonial-t1 text-center',
                    link: 'https://g.co/kgs/r64TuW'
                },
                {
                    id: 2,
                    img: './images/testimonial/chronoexpress.jpg',
                    alt: 'images',
                    text01: 'Très bon service et résultat mieux à ce que je m\'attendais, je recommande fortement l\'agence Perfecto. ',
                    text02: 'Un grand merci à Imane pour son suivi et son professionalisme.',
                    text03: '',
                    name: 'Chrono Express',
                    classname: 'testimonial-t1 text-center',
                    link: 'https://g.co/kgs/UC4HQT'
                },
                {
                    id: 3,
                    img: './images/testimonial/groupe-ctei.jpg',
                    alt: 'images',
                    text01: 'Super service! Comprend bien les demandes, exécution ultra rapide. ',
                    text02: 'Livraison dans les délais ! service exemplaire.',
                    text03: '',
                    name: 'Groupe CTEI',
                    classname: 'testimonial-t1 text-center',
                    link: 'https://g.co/kgs/mzgSBx'
                },
                {
                    id: 4,
                    img: './images/testimonial/wc.jpg',
                    alt: 'images',
                    text01: 'Webcolours a fait affaire avec Perfecto à plusieurs reprises ',
                    text02: 'pour ses services spécialisés et a toujours été satisfait du résultat. ',
                    text03: 'Très professionnel!',
                    name: 'Webcolours',
                    classname: 'testimonial-t1 text-center',
                    link: 'https://g.co/kgs/pZMWVd'
                },
                {
                    id: 5,
                    img: './images/testimonial/yesso-transport.jpg',
                    alt: 'images',
                    text01: 'YESSO TRANSPORT DANMARK été ravi d\'avoir travaillé avec Perfecto Agency ',
                    text02: 'pour le projet d\'élaboration de notre superbe Logo. c\'est une  véritable réussite et une visibilité parfaite',
                    text03: 'dans le milieu du transport et de la logistique, et je vous ferai confiance pour tout autre projet avenir. Merci',
                    name: 'Yesso Transport Danmark',
                    classname: 'testimonial-t1 text-center',
                    link: 'https://g.co/kgs/9bwbF6'
                },
                {
                    id: 6,
                    img: './images/testimonial/bct.jpg',
                    alt: 'images',
                    text01: 'Perfecto est une agence a l\'écoute de ces clients , j\'ai eu un service ',
                    text02: 'impeccable et je vous le recommande. Merci pour vos creations au plaisir de faire avec vous !',
                    text03: 'Client Satisfait ( BarcaTransport )',
                    name: 'Barca Transport',
                    classname: 'testimonial-t1 text-center',
                    link: 'https://g.co/kgs/PBduwb'
                },
                {
                    id: 7,
                    img: './images/testimonial/07.jpg',
                    alt: 'images',
                    text01: 'This was such a pleasant experience. Working with "EmyR" designer has been ',
                    text02: 'a real privilege for me. She is responsive, patient and took great care to understand my vision.',
                    text03: 'I am very happy with the end product. I highly recommend her services.',
                    name: 'irene0T',
                    classname: 'testimonial-t1 text-center',
                    link: 'https://99designs.fr/profiles/1130808/designs/1533021'
                },
                {
                    id: 8,
                    img: './images/testimonial/07.jpg',
                    alt: 'images',
                    text01: 'The design was simple and symbolic. It captured the image of a woman without ',
                    text02: 'relying on stereotypic long hair and girly accessories.',
                    text03: 'EmyR gave me many variations, including black and white.',
                    name: 'DebbieJP',
                    classname: 'testimonial-t1 text-center',
                    link: 'https://99designs.fr/profiles/1130808/designs/1231218'
                },
                {
                    id: 9,
                    img: './images/testimonial/07.jpg',
                    alt: 'images',
                    text01: 'Excellent to work with, submitting several different ',
                    text02: 'but interesting designs that looked clean,',
                    text03: 'crisp, and professional.',
                    name: 'Alvinbutler',
                    classname: 'testimonial-t1 text-center',
                    link: 'https://99designs.fr/profiles/1130808/designs/776270'
                }
            ]
        }
    }
    render() {
        return (
            <div className="container">
                <div className="custom-dot dot-t1 dot-s1 none-nav-default mg-dots-s1">
                    <div className="flat-carousel-box data-effect clearfix" data-gap={0} data-column={1} data-column2={1} data-column3={1} data-column4={1} data-column5={1} data-dots="true" data-auto="false" data-nav="false" data-loop="true">
                        <div className="owl-carousel">
                            {
                                this.state.testimonial.map(data => (
                                    
                                    <div className={data.classname} key={data.id}>
                                                    <div className="avatar mg-b29"><img src={data.img} alt={data.alt} /></div>
                                                    <p className="lt-sp03 mg-b25">
                                                        {data.text01}<br /> {data.text02} <br/>{data.text03}
                                                    </p>
                                                    <a href={data.link} target='_blank' rel='noopener noreferrer nofollow'><h3 className="name f-w600">{data.name}</h3></a>
                                    </div> 
                                ))
                            }
                        </div>
                    </div>
                </div> 
            </div>
        );
    }
}

export default Testimonial;
