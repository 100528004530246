const menus = [
    // {
    //     id: 1,
    //     tomenu: "#about",
    //     namemenu: "About"
    // },
    {
        id: 1,
        tomenu: "#services",
        namemenu: "services"
    },
    {
        id: 2,
        tomenu: "#portfolio",
        namemenu: "Portfolio"
    },
    {
        id: 3,
        tomenu: "#testimonial",
        namemenu: "Témoignage"
    },
    {
        id: 4,
        tomenu: "#resume",
        namemenu: "Notre vision"
    },
];



export default menus ;