import React, { Component } from 'react';
// import { HashLink as Link } from 'react-router-hash-link';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import YoutubeEmbed from './YoutubeEmbed';
class Banner extends Component {
    state = {
    open: false
    };

    onOpenModal = () => {
    this.setState({ open: true });
    };

    onCloseModal = () => {
    this.setState({ open: false });
    };
    render() {
        const { open } = this.state;
        return (
            <div className="section slide-personal-Intro-first">
                <section className="banner-section s1" id="home" loading="lazy">
                    <div className="container">
                    <div className="content-text position-relative">
                        <div className="animate-element wow delay5 fadeInDown" data-wow-delay="0.3s">
                        <h1 className="cd-headline clip is-full-width title mg-b29 text-white">
                            <span>Création </span>
                            <span className="cd-words-wrapper color-d4">
                            <b className="is-visible">SiteWeb!</b>
                            <b>Marque!</b>
                            <b>Vidéos!</b>
                            </span>
                        </h1>
                        <p className="lt-sp03 mg-b60 text-white h2">
                        Spécialisés en <strong>transformation numérique</strong>, 
                        <br/>nous conseillons et accompagnons les entreprises et les organisations 
                        <br/>dans la réalisation de leur plein potentiel. Nous pourrons répondre à vos demandes  
                        <br/>sans problème et nous dépasserons assurément vos attentes.
                        </p>
                        </div>
                        <div className="animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                            <div className="fl-btn btn-general btn-hv-border">
                                {/* <Link to="#services" className="border-corner5 f-w500 lt-sp095 text-white " title='En savoir plus'>Savoir plus</Link> */}
                                <button onClick={this.onOpenModal} className="border-corner5 f-w500 lt-sp095 text-white " title='En savoir plus'>Savoir plus <i className="fa fa-play-circle" aria-hidden="true" /></button>
                                <Modal open={open} onClose={this.onCloseModal}>
                                    <YoutubeEmbed embedId="tKcOoyNrzMk" />
                                </Modal>
                            </div>
                        </div>
                    </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Banner;

