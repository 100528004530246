import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class About extends Component {
    constructor(props){
        super(props);
        this.state = {
            profile: [
                {
                    id: 1,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/html.png',
                    alt: 'images',
                    name: 'HTML',
                    normal: 'Niveau avancé'
                },
                {
                    id: 2,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/css.png',
                    alt: 'images',
                    name: 'CSS',
                    normal: 'Niveau avancé'
                },
                {
                    id: 3,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/vuejs.png',
                    alt: 'images',
                    name: 'VueJs',
                    normal: 'Niveau avancé'
                },
                {
                    id: 4,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/react.png',
                    alt: 'images',
                    name: 'React',
                    normal: 'Niveau avancé'
                },
                {
                    id: 5,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/tailwind.png',
                    alt: 'images',
                    name: 'Tailwind CSS',
                    normal: 'Niveau avancé'
                },
                {
                    id: 6,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/wordpress.png',
                    alt: 'images',
                    name: 'Wordpress',
                    normal: 'Niveau avancé'
                },
                {
                    id: 7,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/timber.png',
                    alt: 'images',
                    name: 'Timber',
                    normal: 'Niveau avancé'
                },
                {
                    id: 8,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/photoshop.png',
                    alt: 'images',
                    name: 'Photoshop',
                    normal: 'Bonne maîtrise'
                },
                {
                    id: 9,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/illustrator.png',
                    alt: 'images',
                    name: 'Illustrator',
                    normal: 'Bonne maîtrise'
                },
                {
                    id: 10,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/adobe-xd.png',
                    alt: 'images',
                    name: 'Adobe XD',
                    normal: 'Bonne maîtrise'
                },
                {
                    id: 11,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/after-effect.png',
                    alt: 'images',
                    name: 'After Effect',
                    normal: 'Bonne maîtrise'
                },
                {
                    id: 12,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/adobe-premiere.png',
                    alt: 'images',
                    name: 'Premiere',
                    normal: 'Bonne maîtrise'
                },
                {
                    id: 13,
                    classname: 'profile-link border-corner2 d-flex align-items-center',
                    images: './images/section/audition.png',
                    alt: 'images',
                    name: 'Audition',
                    normal: 'Bonne maîtrise'
                }
            ]
        }
    }
    render() {
        return (
            <div className="background-white">
                <div className="d-lg-flex">
                    <div className="col-left">
                        <div className="featured-post animate-element wow delay5 fadeInUp" data-wow-delay="0.5s">
                            <img src="images/section/03.png" alt="images"/>
                        </div>
                    </div>
                    <div className="col-right">
                        <div className="flat-spacer" data-desktop="105" data-mobile="50" data-smobile="50"></div>
                        <div className="flat-title t1">
                            <div className="animate-element wow delay5 fadeInDown" data-wow-delay="0.5s">
                                <h4 className="sub-title mg-b22">À propos de nous</h4>
                                <h2 className="title-section mg-b26 color-d12">Perfecto <span className="color-d4">vous aide.</span></h2>
                                <p>Grâce à notre expertise, nous créons des <strong>solutions performantes</strong> et sur mesure qui répondent efficacement à vos besoins et attentes.<br/><br/>
                                <strong>Qualité</strong>, <strong>performance</strong> et <strong>ergonomie</strong> sont les caractéristiques que nous priorisons dans nos réalisations. 
                                    Nous partons du besoin pour créer la solution adéquate, en coordination avec le client, tout en passant par un riche processus de réflexion et de planification.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-list">
                    <div className="flat-carousel-box data-effect clearfix" data-gap="30" data-column="4" data-column2="3" data-column3="2" data-column4="1" data-column5="1" data-dots="false" data-auto="false" data-nav="false" data-loop="true">
                        <div className="owl-carousel">
                            {
                                this.state.profile.map(data => (
                                    <div className={data.classname} key={data.id}>
                                        <div className="featured-post">
                                            <img src={data.images} alt={data.alt} width="54" height="100"/>
                                        </div>
                                        <div className="content-inside">
                                            <h3 className="name"><Link to="#">{data.name}</Link></h3>
                                            <span className="t-normal">{data.normal}</span>
                                        </div>
                                    </div>    
                                ))
                            }                                                          
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;
